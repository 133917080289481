import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';
import ConflictListItem from '@molecules/ConflictListItem/ConflictListItem';
import Modal from '@organisms/Modal/Modal';
import { addListToCartReason, addOrderToCartReason } from '@hooks/useCheckCartConflict';
import {
  StyledCartConflict,
  StyledCartConflictButton,
  StyledCartConflictHeaderLabel,
  StyledCartConflictModalBody,
  StyledCartConflictModalFooter,
  StyledCartConflictModalFooterWrapper,
  StyledCartConflictModalHeader,
  StyledCartConflictModalHeaderNoReplacement,
  StyledCartConflictModalHeaderText,
  StyledCartConflictModalReplaceText,
} from './CartConflictModal.styles';
import useCartConflict from './useCartConflict';
import { AxfoodReplacementProductViewModel, UnitData } from '@api/generated/storefront';

const CartConflictModal = () => {
  const { t } = useTranslation('cartConflictModal');
  const [hoveredItemCode, setHoveredItemCode] = useState<string | null>(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const {
    onCloseHandler,
    onQuantityChange,
    removeProduct,
    replace,
    resolveAndCloseConflictModal,
    removeAllNonReplaceable,
    onConflictListItemHandler,
    showNonReplaceable,
    nonReplaceableItems,
    currentItemIndex,
    replaceableItems,
    currentItem,
    selectedReplacementItem,
    dontReplaceLoading,
    cartConflictModalBody,
    replaceLoading,
    continueLoading,
    conflictingModal,
  } = useCartConflict();

  return (
    <Modal size="medium" hasCloseIcon onClose={onCloseHandler} noPadding overflowHidden>
      <StyledCartConflict data-testid="cart-conflict-modal">
        <StyledCartConflictModalHeader sticky={hasScrolled}>
          <StyledCartConflictModalHeaderText>
            <Heading variant="h2" size="small">
              {showNonReplaceable ? t('productsNotAvailable') : t('productNotInStock')}
            </Heading>
            {showNonReplaceable ? (
              <Text size="small" type="body" color="gray">
                {nonReplaceableItems.length > 1
                  ? `${t('products', { amount: nonReplaceableItems.length })}`
                  : `${t('singleProduct')}`}
              </Text>
            ) : (
              <Text size="small" type="body" color="gray">{`${currentItemIndex + 1} / ${replaceableItems.length}`}</Text>
            )}
            <StyledCartConflictHeaderLabel variant="h3" label>
              {t('outOfStock')}
            </StyledCartConflictHeaderLabel>
          </StyledCartConflictModalHeaderText>
          {showNonReplaceable && !currentItem?.partialOutOfStock && (
            <StyledCartConflictModalHeaderNoReplacement>
              <Text type="body">{t('replacementItemsCouldNotBeFound')}</Text>
            </StyledCartConflictModalHeaderNoReplacement>
          )}
          {currentItem && (
            <ConflictListItem
              product={currentItem.entryProduct as AxfoodReplacementProductViewModel & { pickUnit?: UnitData }}
              pickQuantity={currentItem.pickQuantity as number}
              availableQuantity={currentItem.availableQuantity as number}
              partialOutOfStock={currentItem.partialOutOfStock}
              variant="conflicting"
            />
          )}
        </StyledCartConflictModalHeader>
        <StyledCartConflictModalBody
          onScroll={(e) => (e.currentTarget.scrollTop > 0 ? setHasScrolled(true) : setHasScrolled(false))}
          ref={cartConflictModalBody}
        >
          {!showNonReplaceable && (
            <StyledCartConflictModalReplaceText>
              <Heading variant="h3" label>
                {t('replaceWith')}
              </Heading>

              <Text type="detail" color="red">
                {t('sizeVariation')}
              </Text>
            </StyledCartConflictModalReplaceText>
          )}
          {currentItem && !showNonReplaceable && (
            <div>
              {currentItem.replacementProductsForProduct?.map((item: any) => (
                <ConflictListItem
                  editable
                  product={item}
                  pickQuantity={item.pickQuantity}
                  availableQuantity={item.availableQuantity}
                  variant="replaceable"
                  key={`replaceable-item-${currentItem.entryProduct?.code}-${item.code}`}
                  onClick={() => {
                    onConflictListItemHandler(item);
                  }}
                  onQuantityChange={(qty: number) => {
                    onQuantityChange(qty, item);
                  }}
                  selected={selectedReplacementItem?.code === item.code}
                  hovered={hoveredItemCode === item.code}
                  mouseEnter={() => {
                    setHoveredItemCode(item.code);
                  }}
                  mouseLeave={() => {
                    setHoveredItemCode(null);
                  }}
                />
              ))}
            </div>
          )}
          {showNonReplaceable && !currentItem && (
            <div>
              {nonReplaceableItems.map((item) => (
                <ConflictListItem
                  key={item.entryProduct?.code}
                  product={item.entryProduct as AxfoodReplacementProductViewModel & { pickUnit?: UnitData }}
                  variant="non-replaceable"
                  partialOutOfStock={item.partialOutOfStock}
                  pickQuantity={item.pickQuantity as number}
                  availableQuantity={item.availableQuantity as number}
                  addToCartDisabled={item.entryProduct?.addToCartDisabled}
                />
              ))}
            </div>
          )}
        </StyledCartConflictModalBody>

        <StyledCartConflictModalFooter>
          <StyledCartConflictModalFooterWrapper>
            {!showNonReplaceable ? (
              <>
                <StyledCartConflictButton
                  theme="secondary"
                  wide
                  onClick={() => removeProduct()}
                  isSubmitting={dontReplaceLoading}
                >
                  {t('dontReplace')}
                </StyledCartConflictButton>
                <StyledCartConflictButton
                  wide
                  spacingLeft
                  onClick={replace}
                  disabled={!showNonReplaceable && !selectedReplacementItem}
                  isSubmitting={replaceLoading}
                >
                  {t('replace')}
                </StyledCartConflictButton>
              </>
            ) : (
              <>
                <StyledCartConflictButton theme="secondary" wide onClick={onCloseHandler} isSubmitting={continueLoading}>
                  {t('cancel')}
                </StyledCartConflictButton>
                <StyledCartConflictButton
                  wide
                  spacingLeft
                  onClick={() =>
                    conflictingModal?.reason === addOrderToCartReason || conflictingModal?.reason === addListToCartReason
                      ? resolveAndCloseConflictModal()
                      : removeAllNonReplaceable()
                  }
                  isSubmitting={continueLoading}
                >
                  {t('continue')}
                </StyledCartConflictButton>
              </>
            )}
          </StyledCartConflictModalFooterWrapper>
        </StyledCartConflictModalFooter>
      </StyledCartConflict>
    </Modal>
  );
};

export default CartConflictModal;
