import Modal from '@organisms/Modal/Modal';
import Heading from '@atoms/Heading/Heading';
import useTranslation from 'next-translate/useTranslation';
import DynamicNamespaces from 'next-translate/DynamicNamespaces';
import clearCart from '@api/clearCart';
import { convertProductsToAnalyticsEcommerceItems } from '@helpers/analyticsHelpers/analyticsProduct';
import pushGTMEvent, { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import useCart from '@hooks/useCart';

interface Props {
  visible: boolean;
  setVisibility: (visibility: boolean) => void;
  tracking: { actionFieldList: string; category: string; action: string };
}
const EmptyCartModal = ({ visible, setVisibility, tracking }: Props) => {
  const { cart, refreshCart } = useCart();

  const closeEmptyCartModal = () => {
    setVisibility(false);
  };

  const trackEmptyCart = () => {
    const listName = tracking.actionFieldList;
    const products = cart?.products;
    pushGTMEcommerceEvent({
      event: EcommerceEvent.REMOVE_FROM_CART,
      ecommerce: {
        items: convertProductsToAnalyticsEcommerceItems(products || [], undefined, listName),
      },
    });

    pushGTMEvent({
      event: 'Track',
      category: tracking.category,
      action: tracking.action,
      label: '',
      value: 0,
    });
  };

  const emptyCart = () =>
    new Promise((resolve, reject) => {
      const isReplacingOrder = !!cart?.orderReference;
      clearCart(isReplacingOrder)
        .then((res) => refreshCart(res.data))
        .then(() => {
          closeEmptyCartModal();
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        })
        .then(() => trackEmptyCart())
        .then(() => {
          resolve('Successfully emptied cart');
        })
        .catch(() => {
          reject(new Error('Failed to clear cart'));
        });
    });

  const ModalWithTranslation = () => {
    // because modal namespace is dynamic we need to initialize the
    // useTranslation function from inside a child component
    const { t } = useTranslation('modal');
    return (
      <Modal
        size="small"
        onClose={closeEmptyCartModal}
        onConfirm={emptyCart}
        closeOnEscape
        cancelButtonText={t('modal->cancel')}
        confirmButtonText={t('modal->cart->empty->confirm')}
        buttonWidth={100}
      >
        <Heading variant="h3">{t('modal->cart->empty->title')}</Heading>
      </Modal>
    );
  };

  return <DynamicNamespaces namespaces={['modal']}>{visible && <ModalWithTranslation />}</DynamicNamespaces>;
};

export default EmptyCartModal;
