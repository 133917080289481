import { useEffect, useState } from 'react';
import { useAppDispatch } from '@hooks/useAppDispatch';
import DynamicNamespaces from 'next-translate/DynamicNamespaces';
import Trans from 'next-translate/Trans';
import CART from '@helpers/cartKeys';
import trackMergeCartModal, { trackMergeCartModalClose } from '@helpers/analyticsHelpers/trackMergeCartModal';
import Modal from '@organisms/Modal/Modal';
import useCustomRouter from '@hooks/useCustomRouter';
import {
  StyledMergeCartButton,
  StyledMergeCartHeading,
  StyledMergeCartText,
} from '@components/organisms/MergeCartModal/MergeCartModal.styles';
import { restoreCart } from '@api/interfaces/cartApi';
import { setIsLoggingIn } from '@slices/loginSlice';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import usePersistedCart from '@hooks/usePersistedCart';
import useCustomer from '@hooks/useCustomer';
import useVouchers from '@hooks/useVouchers';
import useCart from '@hooks/useCart';
import useStore from '@hooks/useStore';

const MergeCartModal = () => {
  const router = useCustomRouter();
  const [showMergeCartModal, setShowMergeCartModal] = useState(false);
  const { refreshCustomer } = useCustomer();
  const [showOrderClosedModal, setShowOrderClosedModal] = useState(false);
  const { persistedCart, clearCache } = usePersistedCart();
  const { refreshVouchers } = useVouchers();
  const { cart, refreshCart } = useCart();
  const dispatch = useAppDispatch();
  const { refreshStore } = useStore();

  const closeModal = () => {
    setShowMergeCartModal(false);
    clearCache();
  };

  const handleRestoreCart = async (action: string) => {
    try {
      trackMergeCartModal(action);

      const res = await restoreCart(action);
      closeModal();
      setShowOrderClosedModal(res.data.openOrdersClosed);

      dispatch(setIsLoggingIn(false));
      await Promise.all([refreshCart(res.data.cart), refreshCustomer(), refreshVouchers(), refreshStore()]);

      const { from, name, productCode, login, ...query } = router.query;
      const { pathname } = router;
      router.push({ pathname, query }, { pathname }, { shallow: true });
    } catch (e) {
      console.error(e);
    }
  };
  /**
   * On close without any action
   */
  const onCloseModalHandler = () => {
    trackMergeCartModalClose();
    handleRestoreCart(CART.KEEP_SESSION);
  };

  useEffect(() => {
    setShowMergeCartModal(true);
    pushGTMEvent({
      event: 'Track',
      category: 'cart_merge_modal',
      action: 'merge_modal_shown',
      label: '',
      value: 0,
    });
  }, []);

  return (
    <DynamicNamespaces namespaces={['mergeCart']}>
      {showOrderClosedModal && (
        <Modal
          size="small"
          onClose={() => setShowOrderClosedModal(false)}
          confirmButtonText="Ok"
          onConfirm={() => setShowOrderClosedModal(false)}
        >
          <StyledMergeCartHeading variant="h2" color="black" size="small">
            <Trans i18nKey="mergeCart:mergeCart->closedOrder->title" />
          </StyledMergeCartHeading>
          <StyledMergeCartText type="body" color="black">
            <Trans i18nKey="mergeCart:mergeCart->closedOrder->text" />
          </StyledMergeCartText>
        </Modal>
      )}

      {showMergeCartModal && persistedCart && (
        <Modal size="medium" hasCloseIcon onClose={onCloseModalHandler}>
          <div>
            <div>
              <StyledMergeCartHeading variant="h2" color="black" size="small">
                <Trans i18nKey="mergeCart:mergeCart->title" />
              </StyledMergeCartHeading>
              <StyledMergeCartText type="body" color="black">
                <Trans
                  i18nKey="mergeCart:mergeCart->text->savedCart"
                  values={{
                    count: persistedCart.totalPersistedCartProducts,
                    date: persistedCart.persistedCartFormattedDate,
                  }}
                />
              </StyledMergeCartText>
              <StyledMergeCartText type="body" color="black">
                <Trans i18nKey="mergeCart:mergeCart->text->actions" />
              </StyledMergeCartText>
            </div>

            {!!cart && (
              <div>
                <StyledMergeCartButton onClick={() => handleRestoreCart(CART.KEEP_SESSION)}>
                  {cart.totalUnitCount > 1 ? (
                    <Trans
                      i18nKey="mergeCart:mergeCart->actions->continueWithSession"
                      values={{ count: cart.totalUnitCount }}
                    />
                  ) : (
                    <Trans
                      i18nKey="mergeCart:mergeCart->actions->continueWithOneItemSession"
                      values={{ count: cart.totalUnitCount }}
                    />
                  )}
                </StyledMergeCartButton>
                <StyledMergeCartButton onClick={() => handleRestoreCart(CART.KEEP_PERSISTENT)}>
                  {persistedCart.totalPersistedCartProducts > 1 ? (
                    <Trans
                      i18nKey="mergeCart:mergeCart->actions->continueWithPersistent"
                      values={{
                        count: persistedCart.totalPersistedCartProducts,
                      }}
                    />
                  ) : (
                    <Trans
                      i18nKey="mergeCart:mergeCart->actions->continueWithOneItemPersistent"
                      values={{
                        count: persistedCart.totalPersistedCartProducts,
                      }}
                    />
                  )}
                </StyledMergeCartButton>
                <StyledMergeCartButton onClick={() => handleRestoreCart(CART.MERGE)}>
                  <Trans
                    i18nKey="mergeCart:mergeCart->actions->continueWithMerged"
                    values={{
                      count: persistedCart.totalPersistedCartProducts + cart.totalUnitCount,
                    }}
                  />
                </StyledMergeCartButton>
              </div>
            )}
          </div>
        </Modal>
      )}
    </DynamicNamespaces>
  );
};

export default MergeCartModal;
