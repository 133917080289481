import { getPersistedCart } from '@api/interfaces/cartApi';
import useSWRMutation from 'swr/mutation';
import { AxfoodMergeCartViewModel } from '@api/generated/storefront';
import useSWR from 'swr';

const cacheKey = '/cart/merge';

const usePersistedCart = () => {
  // This dummy hook (no fetcher) is required to cache the result from useSWRMutation (and trigger re-render)
  // https://github.com/vercel/swr/issues/2267
  const { data, mutate } = useSWR<AxfoodMergeCartViewModel | undefined>(cacheKey, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });

  const clearCache = () => {
    mutate(undefined);
  };

  const { trigger } = useSWRMutation(cacheKey, getPersistedCart, {
    populateCache: true,
    revalidate: false,
  });

  return {
    persistedCart: data,
    trigger,
    clearCache,
  };
};

export default usePersistedCart;
